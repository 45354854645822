<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card">
                <el-row style="height:40px;">
                    <!-- <el-button type="primary" class="buttons">同步会员</el-button> -->
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="所属店铺" >
                        <el-select 
                            size="small"
                            v-model="req.shop_id" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in shopList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    
                    <el-form-item label="用户普通等级" >
                        <el-select 
                            size="small"
                            v-model="req.grade_id" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in typeSource"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="用户积分等级" >
                        <el-select 
                            size="small"
                            v-model="req.integral_grade_id" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in typeSource2"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-input
                            size="small"
                            placeholder="请输入关键词搜索"
                            prefix-icon="el-icon-search"
                            v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="getList">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="会员名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                    <el-table-column label="昵称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.nick }}</template>
                    </el-table-column>
                    <el-table-column label="积分"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.integral }}</template>
                    </el-table-column>
                    <el-table-column label="手机号"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.mobile }}</template>
                    </el-table-column>

                    <el-table-column label="省"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.province }}</template>
                    </el-table-column>
                    <el-table-column label="市"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.city }}</template>
                    </el-table-column>
                    <el-table-column label="区"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.area?scope.row.area:scope.row.city }}</template>
                    </el-table-column>
                    <el-table-column label="乡镇"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.town }}</template>
                    </el-table-column>

                    <el-table-column label="普通等级"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.grade_info?scope.row.grade_info.name:''  }}</template>
                    </el-table-column>
                 <el-table-column label="积分等级"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.integral_grade_info?scope.row.integral_grade_info.name:''  }}</template>
                    </el-table-column>
                    <el-table-column label="所属店铺"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.shop_info.name }}</template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <!-- <el-button type="text" size="small">
                                数据管理
                            </el-button> -->
                            <el-button type="text" size="small" @click="edit(scope.row.id)" >
                                编辑
                            </el-button>
                            <!-- <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>


            </div>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="调整等级"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
        <!-- 创建 -->
            <div style="width:500px;margin:0 auto;background:#fff;">
                <div style="text-align:left;">
                <el-row>
                            <p style="width:100%;font-size:16px;line-height:50px;height:50px;">修改等级：
                                <el-select size="small" style="width:350px;" v-model="saveData.grade_id">
                                        <el-option
                                            v-for="item in typeSource"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                </el-select>
                            </p>
                </el-row>
                    <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                        <el-button type="primary" @click="save();">保 存</el-button>
                        <el-button type="primary" @click="getList();createShareFlag=false;">关 闭</el-button>
                    </div>
                </div> 
            </div>
        </el-dialog>
        <!-- 页码 -->
        <div class="pageDiv" >
            <el-pagination
                style="margin:10px 75px 10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>
        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
    props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            saveData:{
                grade_id:'',
                id:''
            },
            thead:[
                '昵称',
                '积分',
                '手机号',
                '省',
                '市',
                '区',
                '乡镇',
                '普通等级',
                '积分等级',
                '所属店铺',
                '操作'
            ],
            // 获取滚动区域高度
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            shopList:[],
            typeSource:[],
            typeSource2:[],
            req:{
                limit:30,
                page:1,
                keywords:'',
                grade_id:'',
                shop_id:'',
                integral_grade_id:''
            }
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
 // 等级数据保存
        save(){
            const loading = this.$loading({
                lock: true,
                text: '保存数据中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // 根据currentId判断是否大于0来保存数据
            if(this.currentId>0){
                this.saveData.id = this.currentId;
                // 添加等级
                apis.memberEdit(this.saveData).then(res=>{
                    tools.msg(res,this,1);
                    loading.close();
                }).catch(err=>{
                    tools.err(err,this);
                    loading.close();
                });
                return;
            }
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            console.log(this.req);
            apis.memberList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.staffDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        // 获取数据源列表   
        apis.levelList({
            type:'1'
        }).then(res=>{
            if(tools.msg(res,this)){
                this.typeSource = res.data.data;
            }
        }).catch(err=>{
            tools.err(err,this);
        });
        // 积分等级数据
        apis.levelList({
            type:'2'
        }).then(res=>{
            if(tools.msg(res,this)){
                this.typeSource2 = res.data.data;
            }
        }).catch(err=>{
            tools.err(err,this);
        });
        // 店铺等级数据
        apis.shopList().then(res=>{
            if(tools.msg(res,this)){
                this.shopList = res.data.data;
            }
        }).catch(err=>{
            tools.err(err,this);
        });


        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>